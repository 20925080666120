<template>
  <div>
    <lp-datatable
      ref="payoutTableRef"
      title="Payout"
      store-module="payout"
      :extra-parameter="extraParam"
      store-data-endpoint="getAdminPayoutRecordsFiltered"
      store-getter-endpoint="getPayoutRecordFilteredList"
      pagination-state-key="payoutRecordFilteredList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="external_invoice_ref"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @status-filter-btn-clicked="statusFilterBtnClicked"
      @actionBtn-clicked="actionBtnClicked"
      @aditBtn-clicked="editBtnClicked"
      @viewBtn-clicked="viewBtnClicked"
      @deleteBtn-clicked="deleteBtnClicked"
      @extraBtn-clicked="extraBtnClicked"
    >
      <template #admin_action="row">
        <b-btn
          size="sm"
          :disabled="row.data.transaction_status !== 'SUCCESS'"
          @click="showGenerateReceiptModal(row.data)"
        >
          Generate Receipt
        </b-btn>
      </template>
    </lp-datatable>
    <b-modal
      ref="rawDataViewerRef"
      size="xl"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Raw Data"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <json-viewer
        :value="currentRowData"
        :expand-depth="5"
        copyable
        boxed
        sort
      />
    </b-modal>

    <b-modal
      ref="confirmPayoutModalRef"
      size="md"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Payout Confirmation"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <confirm-payout-form
        :validated-data="validatedPayoutData"
        @cancel-payout-clicked="resetFormConfirmValidate"
        @confirm-payout-clicked="confirmPayout($event)"
      />
    </b-modal>

    <b-modal
      ref="validateAccountModalRef"
      size="md"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Make Payout"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <validate-payout-form
        ref="validatePayoutFormRef"
        @cancel-payout-clicked="resetFormValidate"
        @validate-payout-clicked="validatePayout($event)"
      />
    </b-modal>

    <b-modal
      ref="PayoutReceiptModal"
      size="xl"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Raw Data"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      body-class="bg-receipt"
    >
      <receipt-payout-component :receipt-data="currentRowData" />
    </b-modal>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import axios from 'axios'
import Config from '@/views/administration/payout/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'

import LpBaseFileUpload from '@/leanpay-components/LPBaseFileUpload.vue'
import ReceiptPayoutComponent from '@/views/administration/payout/component/ReceiptPayoutComponent.vue'
import ValidatePayoutForm from '@/views/administration/payout/component/validatePayoutForm.vue'
import ConfirmPayoutForm from '@/views/administration/payout/component/confirmPayoutForm.vue'

export default {
  components: {
    ConfirmPayoutForm,
    ValidatePayoutForm,
    ReceiptPayoutComponent,
    LpDatatable,
    ValidationObserver,
    ValidationProvider,
    LpBaseFileUpload,
    vSelect,
    JsonViewer,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      currentRowData: {},
      cardTitle: 'Payout',
      dataGridEndpoint: [],
      module: 'payout',
      confirmCheckbox: false,

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      validatedPayoutData: {},

      proofForm: {},
      extraParam: {
        transaction_status: ['PENDING', 'SUCCESS', 'FAILED', 'PENDING_APPROVAL'],
      },

      record_status: 1, // active only
      searchField: 'transaction_invoice_no',

      searchFieldOpts: [
        { value: 'transaction_invoice_no', text: 'Invoice No' },
      ],
    }
  },
  computed: {

    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    tableConfig() {
      if (Config) {
        return Config.tableData()
      }
      return {}
    },
    tableSettings() {
      const list = this.$store.getters[`${this.module}/getPaginationInfo`]
      // const { list } = dummyRes.data
      const perPage = this.selectedPerPage
      let listLength
      if (list !== null) {
        listLength = this.GridData.recordsTotal
      } else {
        listLength = 100
      }
      return {
        rows: listLength,
        perPage,
        refresh: true,
        filter: false,
        pagination: true,
        search: true,
      }
    },
    TableHeader() {
      const x = this.tableConfig.tableHeader
      return x.filter(y => y.visible)
    },
    TableActions() {
      const x = this.tableConfig.tableAction
      return x.filter(y => y.enable)
    },
    sortObj() {
      const obj = {
        parameter_name: this.sortBy,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    GridData() {
      const list = this.$store.getters[`${this.module}/getPayoutRecordList`]
      if (list !== null) {
        return list
      }
      return []
    },
    GridSettings() {
      const list = this.$store.getters[`${this.module}/getPaginationInfo`]

      console.log('---->', list)
      if (list !== null) {
        return {
          draw: list.draw,
          recordsTotal: list.record_total,
          next_page: list.next_page,
          next_page_length: list.next_page_length,
          next_page_start: list.next_page_start,
          previous_page_length: list.previous_page_length,
          previous_page_start: list.previous_page_start,
          previouse_page: list.previouse_page,
          recordsFiltered: list.record_filtered,
        }
      }
      return []
    },
  },
  async mounted() {
    // this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Payout' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    await this.$store.dispatch('general/getListVerificationChannel')

    // this.initDataGrid()
  },
  methods: {
    async resetFormValidate() {
      this.$refs.validateAccountModalRef.hide()
    },
    async resetFormConfirmValidate() {
      this.$refs.confirmPayoutModalRef.hide()
    },
    async validatePayout(e) {
      if (e.Success) {
        this.validatedPayoutData = e
        this.$refs.confirmPayoutModalRef.show()
      }
    },
    async confirmPayout(e) {
      console.log(e)
      this.$refs.validateAccountModalRef.hide()
      this.$refs.confirmPayoutModalRef.hide()
    },
    async checkBankAccount(rowData) {
      let parsed
      let payload
      if (rowData.data) {
        parsed = JSON.parse(rowData.data)
        console.log('parsed', parsed)

        payload = {
          payout_account_number: rowData.payout_account_number,
          payout_service_id: parsed.payout_service_id,
        }
      }
      await this.toggleModalShowStatus(payload)
    },

    async toggleModalShowStatus(payload = null) {
      const statusStore = await this.$store.getters['systemConstants/getCheckBankAccountModalStatus']
      const newStatus = !statusStore
      await this.$store.dispatch('systemConstants/toggleCheckBankAccountModal', newStatus)
      await this.$store.dispatch('systemConstants/updateCheckBankAccountModal', payload)
    },

    statusFilterBtnClicked(e) {
      if (e === 'allPayoutFilter') {
        this.extraParam.transaction_status = ['PENDING', 'SUCCESS', 'FAILED', 'PENDING_APPROVAL']
        this.$refs.payoutTableRef.initDataGrid()
      }
      if (e === 'pendingPayoutFilter') {
        this.extraParam.transaction_status = ['PENDING']
        this.$refs.payoutTableRef.initDataGrid()
      }
      if (e === 'successPayoutFilter') {
        this.extraParam.transaction_status = ['SUCCESS']
        this.$refs.payoutTableRef.initDataGrid()
      }
      if (e === 'failedPayoutFilter') {
        this.extraParam.transaction_status = ['FAILED']
        this.$refs.payoutTableRef.initDataGrid()
      }
      if (e === 'pendingApprovalPayoutFilter') {
        this.extraParam.transaction_status = ['PENDING_APPROVAL']
        this.$refs.payoutTableRef.initDataGrid()
      }

      console.log('statusFilterBtnClicked', e)
    },
    uploadFiles() {
      const formData = new FormData()

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.files.length; i++) {
        // formData.append('file', this.files[i])
        formData.append('files', this.files[i])
      }
      this.$store.dispatch('mediaStore/uploadMerchantImageCdn', formData).then(res => {
        if (res.data.response_code === 2000) {
          console.log('data', res.data.data)
          this.filesUrl = res.data.data
        }
      })
    },
    onUpdate() {
      console.log(this.proofForm)
      const payload = {
        // ...this.proofForm,
        id: this.proofForm.id,
        transaction_status: this.proofForm.transaction_status,
        image_path: this.proofForm.image_path,
      }

      this.$store.dispatch('payout/updateAdminPayoutRecords', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.initDataGrid()
          this.cancelForm()
        }
      })
    },
    cancelForm() {
      this.$refs.payoutProofAdmin.hide()
    },
    processForm(field, $event) {
      if (field === 'image_path') {
        // eslint-disable-next-line prefer-destructuring
        console.log('proofForm imagePath', $event)
        this.proofForm[field] = $event
      } else {
        this.proofForm[field] = $event
      }
    },
    showGenerateReceiptModal(data) {
      this.currentRowData = data
      this.$refs.PayoutReceiptModal.show()
    },
    triggerCallbackData(invoiceNo) {
      console.log(invoiceNo)

      const payload = {
        invoice_nos: [invoiceNo],
      }

      this.$store.dispatch('general/omniCallbackBulkCallback', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.$swal.fire({
            title: 'Successfully Triggered',
            text: 'Callback Triggered',
            icon: 'success',
          })
        } else {
          const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
          this.$swal.fire({
            title: `Error - ${res.data.response_code}`,
            html: desc,
            icon: 'error',
          })
        }
      })
    },
    actionBtnClicked(e) {
      if (e.actionName === 'openUpdatePayoutStatus') {
        this.currentRowData = e.rowData
        this.proofForm = e.rowData
        this.$refs.payoutProofAdmin.show()
      }
      if (e.actionName === 'triggerCallbackData') {
        console.log(e.rowData.transaction_invoice_no)

        this.triggerCallbackData(e.rowData.transaction_invoice_no)
      }
      if (e.actionName === 'viewRawData') {
        this.currentRowData = e.rowData
        this.$refs.rawDataViewerRef.show()
      }
      if (e.actionName === 'checkBankAccount') {
        this.currentRowData = e.rowData
        console.log(this.currentRowData)
        this.checkBankAccount(this.currentRowData)
      }
      if (e.actionName === 'generateReceipt') {
        this.currentRowData = e.rowData
        console.log(this.currentRowData)
        this.$refs.PayoutReceiptModal.show()
      }
      // console.log(e)
    },
    deleteBtnClicked(e) {
      console.log(e)
    },
    viewBtnClicked(e) {
      console.log(e)
    },
    editBtnClicked(e) {
      console.log(e)
    },
    extraBtnClicked(e) {
      if (e === 'adminPayoutButtonRef') {
        console.log('hi')
        this.$refs.validateAccountModalRef.show()
      }
      console.log(e)
    },
    pagination(e) {
      if (this.tableSettings.search) {
        const payload = {
          start: (e - 1) * this.tableSettings.perPage,
          length: this.tableSettings.perPage,
          sort: this.sortObj,
          search: this.searchTerm,
        }
        this.isBusy = true
        this.$store.dispatch(this.dataGridEndpoint, payload).then(() => {
          this.isBusy = false
        })
      }
    },
    sortingChanged(ctx) {
      this.sortDesc = !this.sortDesc
      this.sortBy = ctx.sortBy
      const payload = {
        start: 0,
        length: this.tableSettings.perPage,
        sort: this.sortObj,
        search: this.searchTerm,
      }
      this.isBusy = true
      this.$store.dispatch(this.dataGridEndpoint, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    searchGrid(vuexEndpoint) {
      const payload = {
        start: 0,
        length: this.tableSettings.perPage,
        sort: this.sortObj,
        search: this.searchTerm,
      }
      this.isBusy = true
      this.$store.dispatch(vuexEndpoint, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    initDataGrid() {
      console.log('plans')
      this.isBusy = true
      const payload = {
        limit: this.tableSettings.perPage,
        skip: 0,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
      }
      this.isBusy = true
      this.$store.dispatch(`${this.module}/getAdminPayoutRecords`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false

        this.searchTerm = ''
      })
    },
  },
}
</script>

<style scoped lang="scss">
.bg-receipt {
  background-color: #f1f4f9;
}
</style>
